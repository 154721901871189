import React from 'react'
import {
  Card,
  Button,
  CardContent,
  CardActions,
  Typography,
  List,
} from '@mui/material'
import { useAuthentication, useEpiContent } from '@trr/app-shell-data'
import { Content } from 'types'
import {
  useGetRegisteredBookingsQuery,
  useGetFutureBookedMeetingsQuery,
} from 'api'
import { hasDeniedAccessRoles } from 'utils'

import { Booking, Meeting } from './components'

const Bookings = () => {
  const content = useEpiContent<Content>()
  const { sub, idp, userRoles } = useAuthentication()

  const { data: bookingsData } = useGetRegisteredBookingsQuery(sub, {
    skip: sub === null || hasDeniedAccessRoles(userRoles),
  })
  const { data: meetingsData } = useGetFutureBookedMeetingsQuery(undefined, {
    skip: sub === null || idp === 'kund',
  })

  const firstMeeting = meetingsData?.[0]

  return (
    <Card data-testid="bookings" sx={{ backgroundColor: 'surface.green' }}>
      <CardContent>
        <Typography variant="h6" component={'h2'}>
          {content?.heading}
        </Typography>
        <List>
          {firstMeeting && (
            <Meeting
              isLast={bookingsData?.length === 0}
              item={firstMeeting}
              key={firstMeeting.id}
            />
          )}
          {bookingsData?.map((booking, index) => (
            <Booking
              isLast={index === bookingsData.length - 1}
              item={booking}
              key={booking.id}
            />
          ))}
        </List>
      </CardContent>
      {bookingsData && bookingsData?.length > 0 && (
        <CardActions>
          <Button
            variant="text"
            data-testid="allBookingsLink"
            href={content?.linkUrl}
            size="small"
          >
            {content?.linkText}
          </Button>
        </CardActions>
      )}
    </Card>
  )
}

export default Bookings
