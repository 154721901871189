import { Adress } from 'types'

const getStageLocation = (isOnline: boolean, adress: Adress): string => {
  if (isOnline) {
    return 'Online'
  }
  const adressInfo = []
  adress?.city && adressInfo.push(adress.city)
  adress?.street && adressInfo.push(adress.street)
  return adressInfo.join(', ') ?? ''
}

export default getStageLocation
