import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getIdToken } from '@trr/app-shell-data'
import {
  BookedMeetingsResponse,
  GetRegisteredBookingsResponse,
  Meeting,
} from 'types'
import { getConfig } from 'utils'

const { API_URL, KLIENT_API_URL } = getConfig()

const trrFetchBaseQuery = () =>
  fetchBaseQuery({
    prepareHeaders: (headers) => {
      headers.set('authorization', `Bearer ${getIdToken() ?? ''}`)
      headers.set('Content-Type', 'application/json')
      headers.set('Accept-Language', 'sv')
      return headers
    },
  })

const api = createApi({
  baseQuery: trrFetchBaseQuery(),
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    getFutureBookedMeetings: builder.query<Meeting[], void>({
      query: () => `${KLIENT_API_URL}/meetings/v2.1/meetings`,
      transformResponse: (response: BookedMeetingsResponse) => {
        const data = response.meetings
          ?.filter(
            (meeting) => new Date(meeting.startTime).getTime() > Date.now()
          )
          .sort((a, b) => a.startTime.localeCompare(b.startTime))

        return data ?? []
      },
    }),
    getRegisteredBookings: builder.query<
      GetRegisteredBookingsResponse,
      string | null
    >({
      query: (userId) =>
        `${API_URL}/aktivitetsbokningar/ActivityRounds/user/${userId ?? ''}/current`,
      transformResponse: (response: GetRegisteredBookingsResponse) => {
        if (response.length > 0) {
          // remove cancelled bookings, take three first
          return response
            .filter(
              (booking) =>
                booking.schedule.lifecycleStatus !== 'CANCELLED' &&
                booking.schedule.lifecycleStatus !== 'COMPLETED'
            )
            .slice(0, 3)
        }
        return response
      },
    }),
  }),
})

export const {
  useGetRegisteredBookingsQuery,
  useGetFutureBookedMeetingsQuery,
} = api

export default api
