import React from 'react'
import { useEpiContent } from '@trr/app-shell-data'
import { date, timespan } from '@trr/frontend-datetime'
import {
  Avatar,
  Box,
  CardActionArea,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import EventIcon from '@mui/icons-material/Event'
import { RegisteredBooking, Content } from 'types'
import { getStageLocation } from 'utils'

const Booking = ({
  item,
  isLast,
}: {
  item: RegisteredBooking
  isLast: boolean
}) => {
  const content = useEpiContent<Content>()
  return (
    <CardActionArea
      data-testid="bookingLink"
      href={`${content?.activityBaseUrl}${item.id}`}
    >
      <ListItem divider={!isLast} alignItems="flex-start">
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: 'white' }}>
            <EventIcon sx={{ color: 'secondary.dark' }} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          data-testid="bookingTextBox"
          primaryTypographyProps={{
            component: 'h6',
            variant: 'subtitle1',
          }}
          primary={item.activity.name}
          secondaryTypographyProps={{
            component: 'div',
          }}
          secondary={
            <>
              <Stack
                direction="row"
                divider={
                  <Box fontSize={13} color="neutral.main">
                    ●
                  </Box>
                }
                spacing={1}
              >
                <Typography
                  variant="body2"
                  color="text.primary"
                  component="span"
                >
                  {date.format(item.schedule.nextPart.start)}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.primary"
                  component="span"
                >
                  {timespan.format(
                    item.schedule.nextPart.start,
                    item.schedule.nextPart.end
                  )}
                </Typography>
              </Stack>
              <Typography variant="caption" fontSize={14}>
                {getStageLocation(
                  item.isOnline,
                  item.schedule.nextPart.physicalAddress
                )}
              </Typography>
            </>
          }
        />
      </ListItem>
    </CardActionArea>
  )
}

export default Booking
