import React from 'react'
import { useEpiContent } from '@trr/app-shell-data'
import { date, timespan } from '@trr/frontend-datetime'
import {
  Avatar,
  Box,
  CardActionArea,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import FaceIcon from '@mui/icons-material/Face'
import { Content, Meeting as MeetingInterface } from 'types'

const Meeting = ({
  item,
  isLast,
}: {
  item: MeetingInterface
  isLast: boolean
}) => {
  const content = useEpiContent<Content>()
  return (
    <CardActionArea data-testid="meetingLink" href={content?.meetingUrl}>
      <ListItem divider={!isLast} alignItems="flex-start">
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: 'white' }}>
            <FaceIcon sx={{ color: 'secondary.dark' }} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          data-testid="meetingTextBox"
          primaryTypographyProps={{
            component: 'h6',
            variant: 'subtitle1',
          }}
          primary={content.meetingHeading}
          secondaryTypographyProps={{
            component: 'div',
          }}
          secondary={
            <>
              <Stack
                direction="row"
                divider={
                  <Box fontSize={13} color="neutral.main">
                    ●
                  </Box>
                }
                spacing={1}
              >
                <Typography
                  variant="body2"
                  color="text.primary"
                  component="span"
                >
                  {date.format(item.startTime)}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.primary"
                  component="span"
                >
                  {timespan.format(item.startTime, item.endTime)}
                </Typography>
              </Stack>
              <Typography variant="caption" fontSize={14}>
                {item.employee?.fullname}
              </Typography>
            </>
          }
        />
      </ListItem>
    </CardActionArea>
  )
}

export default Meeting
