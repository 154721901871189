import React from 'react'
import { useAuthentication } from '@trr/app-shell-data'
import {
  useGetFutureBookedMeetingsQuery,
  useGetRegisteredBookingsQuery,
} from 'api'
import { NoneFound } from 'features/NoneFound'
import { Bookings } from 'features/Bookings'
import { hasDeniedAccessRoles } from 'utils'

export const App = () => {
  const { sub, userRoles, idp } = useAuthentication()
  const {
    data: bookingsData,
    isLoading: bookingsLoading,
    isError: isActivityError,
  } = useGetRegisteredBookingsQuery(sub, {
    skip: sub === null || hasDeniedAccessRoles(userRoles),
  })

  const { data: meetingsData, isLoading: meetingsLoading } =
    useGetFutureBookedMeetingsQuery(undefined, {
      skip: sub === null || idp === 'kund',
    })

  if (bookingsLoading || meetingsLoading) {
    return null
  }
  return (bookingsData?.length === 0 && meetingsData?.length === 0) ||
    (bookingsData?.length === 0 && idp === 'kund') ||
    isActivityError ? (
    <NoneFound />
  ) : (
    <Bookings />
  )
}
